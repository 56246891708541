
  import { defineComponent, ref, watch } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { PermissionObject } from '@/store/modules/Members/PermissionsModule';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { useAbility } from '@casl/vue';

  export default defineComponent({
    name: 'compare-values-table',
    components: {
      Datatable,
    },
    props: {
      value: { type: Object, required: true },
    },
    async setup(props) {
      const tableData = ref(props.value.options);
      const tableTitle = ref(props.value.title);
      const areaName = ref(props.value.areaName);
      const { t, te } = useI18n();
      const perms = ref<Array<PermissionObject>>([]);
      const { can } = useAbility();

      watch(
        () => props.value,
        (changed) => {
          tableData.value = changed.options;
          areaName.value = changed.areaName;
        }
      );

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const tableHeader = ref([
        {
          name: translate('idColumn'),
          key: 'id',
          sortable: true,
        },
        {
          name: translate('title'),
          key: 'title',
          sortable: true,
        },
        {
          name: translate('value'),
          key: 'value',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
          sortable: false,
        },
      ]);

      return {
        areaName,
        translate,
        perms,
        tableHeader,
        tableTitle,
        tableData,
        can,
      };
    },
  });
