
  import QuickNavDropdown from '@/views/manage/IVE/estimator/QuickNavDropdown.vue';
  import { computed, defineComponent, onMounted, ref, nextTick } from 'vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import Multiselect from '@vueform/multiselect';
  import { useAbility } from '@casl/vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n/index';
  import { Actions, Mutations } from '@/store/enums/StoreEnums';
  import FactorValuesTable from '@/views/manage/IVE/estimator/FactorValuesTable.vue';
  import FactorValuesCreating from '@/views/manage/IVE/estimator/FactorValuesCreating.vue';
  import FactorValuesComparing from '@/views/manage/IVE/estimator/FactorValuesComparing.vue';
  import FactorValuesEditing from '@/views/manage/IVE/estimator/FactorValuesEditing.vue';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import CompareValuesTable from '@/views/manage/IVE/estimator/CompareValuesTable.vue';
  import LandFactors from '@/store/enums/IVELandFactorsEnums';
  import { useRouter, useRoute } from 'vue-router';

  interface Factor {
    factorId: number;
    factorTitle: string;
  }

  export default defineComponent({
    name: 'land-estimator-listing',
    components: {
      QuickNavDropdown,
      Multiselect,
      FactorValuesTable,
      FactorValuesCreating,
      FactorValuesEditing,
      FactorValuesComparing,
      CompareValuesTable,
    },
    async setup() {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const factorData = ref<Factor | undefined>();
      const editData = ref<Factor | undefined>();
      const comparing = ref<number>(0);
      const compareButtonRef = ref<null | HTMLButtonElement>(null);
      const router = useRouter();
      const route = useRoute();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('landingEstimator'), [
          translate('ive'),
        ]);
        reinitializeComponents();
      });

      await store.dispatch(Actions.GET_ALL_IVE_AREAS);
      const areas = computed(() => store.getters.allIveAreasList);
      const selectedArea = ref<number>(
        route.params.id ? route.params.id : areas.value[0].id
      );

      const refreshValues = async () => {
        await store.dispatch(Actions.GET_IVE_AREA_ESTIMATIONS, {
          areaId: selectedArea.value,
          mutation: Mutations.SET_IVE_AREA_ESTIMATIONS,
          factors: LandFactors,
        });
      };

      await refreshValues();

      const handleAreaChange = async () => {
        if (factorData.value != undefined) {
          factorData.value.factorId = 0;
        }
        await nextTick();
        await refreshValues();
      };

      const factorsValues = computed(() => store.getters.areaOptionsValues);
      const numberOfValues = computed(() => {
        return factorsValues.value.reduce(
          (previousValue, currentValue) =>
            previousValue + currentValue.options.length,
          0
        );
      });

      const showFactorCreateModal = (val) => {
        factorData.value = val;
      };
      const showFactorEditModal = (val) => {
        editData.value = val;
      };

      const initCompare = async (val) => {
        ++comparing.value;
        if (compareButtonRef.value) {
          // eslint-disable-next-line
          compareButtonRef.value!.disabled = true;
          compareButtonRef.value.setAttribute('data-kt-indicator', 'on');
        }

        const mutation =
          comparing.value === 1
            ? Mutations.SET_COMPARE_IVE_AREA_ESTIMATIONS
            : Mutations.SET_SECOND_COMPARE_IVE_AREA_ESTIMATIONS;

        await store.dispatch(Actions.GET_IVE_AREA_ESTIMATIONS, {
          areaId: val,
          mutation,
          factors: LandFactors,
        });
        //Deactivate indicator
        compareButtonRef.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        compareButtonRef.value!.disabled = comparing.value === 2;
      };

      const resetComparing = () => {
        comparing.value = 0;
        // eslint-disable-next-line
        compareButtonRef.value!.disabled = false;
      };

      const primaryAreaClasses = computed(() => ({
        col: comparing.value === 0,
        'col-xl-6': comparing.value === 1,
        'col-xl-4': comparing.value === 2,
      }));

      const secondaryAreaClasses = computed(() => ({
        'd-none': comparing.value === 0,
        'col-xl-6': comparing.value === 1,
        'col-xl-4': comparing.value === 2,
      }));

      const tertiaryAreaClasses = computed(() => {
        return comparing.value === 2 ? 'col-xl-4' : 'd-none';
      });

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const ClonArea = (id) => {
        router.push({ name: 'land-estimator-cloning', params: { id } });
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        goTo,
        ClonArea,
        tertiaryAreaClasses,
        primaryAreaClasses,
        secondaryAreaClasses,
        resetComparing,
        comparing,
        factorData,
        editData,
        factorsValues,
        compareFactorsValues: computed(
          () => store.getters.compareAreaOptionsValues
        ),
        secondCompareFactorsValues: computed(
          () => store.getters.secondCompareAreaOptionsValues
        ),
        numberOfValues,
        handleAreaChange,
        translate,
        selectedArea,
        areas,
        can,
        showFactorCreateModal,
        showFactorEditModal,
        refreshValues,
        initCompare,
        compareButtonRef,
      };
    },
  });
