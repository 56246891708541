import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card pt-4 mb-6 mb-xl-9"
}
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bold fs-3 mb-1 fw-bolder" }
const _hoisted_5 = { class: "text-muted fw-semobold fs-7" }
const _hoisted_6 = { class: "card-body pt-0 pb-5" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('read', 'ive'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.tableTitle), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.areaName), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Datatable, {
            "table-header": _ctx.tableHeader,
            "table-data": _ctx.tableData,
            "rows-per-page": 5,
            total: _ctx.tableData.length,
            "enable-items-per-page-dropdown": false
          }, {
            "cell-id": _withCtx(({ row: option }) => [
              _createTextVNode(_toDisplayString(option.id), 1)
            ]),
            "cell-title": _withCtx(({ row: option }) => [
              _createTextVNode(_toDisplayString(option.title), 1)
            ]),
            "cell-value": _withCtx(({ row: option }) => [
              _createTextVNode(_toDisplayString(option.value), 1)
            ]),
            "cell-actions": _withCtx(({ row: option }) => [
              _createTextVNode(" - "),
              _createElementVNode("a", {
                href: "#",
                class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm",
                style: {"visibility":"hidden"},
                onClick: option.value
              }, [
                _createElementVNode("span", _hoisted_8, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen027.svg" })
                ])
              ], 8, _hoisted_7)
            ]),
            _: 1
          }, 8, ["table-header", "table-data", "total"])
        ])
      ]))
    : _createCommentVNode("", true)
}