
  import { computed, defineComponent, ref } from 'vue';
  import { Form } from 'vee-validate';
  import { hideModal } from '@/core/helpers/dom';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n/index';
  import Multiselect from '@vueform/multiselect';

  export default defineComponent({
    name: 'factor-values-comparing',
    props: {
      factorData: { type: Object, required: false },
    },
    components: {
      Form,
      Multiselect,
    },
    emit: ['compare-requested'],
    async setup(props, { emit }) {
      const submitButtonRef = ref<null | HTMLButtonElement>(null);
      const compareFactorValueModalRef = ref<null | HTMLElement>(null);
      const store = useStore();
      const { t, te } = useI18n();
      const mainArea = computed(() => store.getters.estimatorSelectedArea); // .value .id

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const submit = async () => {
        emit('compare-requested', selectedArea.value);
        hideModal(compareFactorValueModalRef.value);
      };

      const areas = computed(() => {
        return store.getters.allIveAreasList.filter((a) => {
          return a.id !== mainArea.value.id;
        });
      });

      const selectedArea = ref<number>(areas.value[0].id);

      return {
        areas,
        translate,
        submit,
        submitButtonRef,
        compareFactorValueModalRef,
        selectedArea,
      };
    },
  });
