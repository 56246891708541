import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card pt-4 mb-6 mb-xl-9"
}
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bold fs-3 mb-1 fw-bolder" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = { class: "svg-icon svg-icon-3" }
const _hoisted_7 = { class: "svg-icon svg-icon-3" }
const _hoisted_8 = { class: "card-body pt-0 pb-5" }
const _hoisted_9 = { class: "svg-icon svg-icon-5 m-0" }
const _hoisted_10 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4",
  "data-kt-menu": "true"
}
const _hoisted_11 = {
  key: 0,
  class: "menu-item px-3"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 1,
  class: "menu-item px-3"
}
const _hoisted_14 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('read', 'ive'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.tableTitle), 1),
            _withDirectives(_createElementVNode("span", { class: "text-muted fw-semobold fs-7" }, _toDisplayString(_ctx.areaName), 513), [
              [_vShow, _ctx.isComparing]
            ])
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_5, [
            (_ctx.can('update', 'ive'))
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn btn-sm btn-flex btn-light-primary me-3",
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#kt_modal_edit_ive_value",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showEditIveModal && _ctx.showEditIveModal(...args)))
                }, [
                  _createElementVNode("span", _hoisted_6, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen035.svg" })
                  ]),
                  _createTextVNode(" Update Existing Values ")
                ]))
              : _createCommentVNode("", true),
            (_ctx.can('create', 'ive'))
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#kt_modal_new_ive_value",
                  type: "button",
                  class: "btn btn-sm btn-flex btn-light-primary",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showCreateIveModal && _ctx.showCreateIveModal(...args)))
                }, [
                  _createElementVNode("span", _hoisted_7, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen035.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate('addNewValue')), 1)
                ]))
              : _createCommentVNode("", true)
          ], 512), [
            [_vShow, !_ctx.isComparing]
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Datatable, {
            "table-header": _ctx.tableHeader,
            "table-data": _ctx.tableData,
            "rows-per-page": 5,
            total: _ctx.tableData.length,
            "enable-items-per-page-dropdown": false
          }, {
            "cell-id": _withCtx(({ row: option }) => [
              _createTextVNode(_toDisplayString(option.id), 1)
            ]),
            "cell-title": _withCtx(({ row: option }) => [
              _createTextVNode(_toDisplayString(option.title), 1)
            ]),
            "cell-value": _withCtx(({ row: option }) => [
              _createTextVNode(_toDisplayString(option.value), 1)
            ]),
            "cell-actions": _withCtx(({ row: option }) => [
              _createElementVNode("button", {
                href: "#",
                class: _normalizeClass(["btn btn-sm btn-light btn-active-light-primary", { disabled: option.isDummy || _ctx.isComparing }]),
                "data-kt-menu-trigger": "click",
                "data-kt-menu-placement": "bottom-end",
                "data-kt-menu-flip": "top-end"
              }, [
                _createTextVNode(_toDisplayString(_ctx.translate('actions')) + " ", 1),
                _createElementVNode("span", _hoisted_9, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr072.svg" })
                ])
              ], 2),
              _createElementVNode("div", _hoisted_10, [
                (_ctx.can('remove', 'ive'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("a", {
                        onClick: ($event: any) => (_ctx.deleteOption(option.id)),
                        class: "menu-link px-3"
                      }, _toDisplayString(_ctx.translate('delete')), 9, _hoisted_12)
                    ]))
                  : _createCommentVNode("", true),
                (option.factorId === 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("a", {
                        onClick: ($event: any) => (_ctx.setOptionAsDefault(option.id)),
                        class: "menu-link px-3"
                      }, _toDisplayString(_ctx.translate('setAsDefault')), 9, _hoisted_14)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["table-header", "table-data", "total"])
        ])
      ]))
    : _createCommentVNode("", true)
}