
  import { computed, defineComponent, ref, watch, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useI18n } from 'vue-i18n/index';
  import { PermissionObject } from '@/store/modules/Members/PermissionsModule';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  export default defineComponent({
    name: 'factor-values-table',
    components: {
      Datatable,
    },
    emit: ['show-create-modal', 'show-edit-modal'],
    props: {
      value: { type: Object, required: true },
      comparing: { type: Number, required: true },
    },
    async setup(props, { emit }) {
      const store = useStore();
      const tableData = ref(props.value.options);
      const tableTitle = ref(props.value.title);
      const areaName = ref(props.value.areaName);
      const factorId = ref(props.value.id);
      const { t, te } = useI18n();
      const perms = ref<Array<PermissionObject>>([]);
      const { can } = useAbility();
      const selectedArea = computed(() => store.getters.estimatorSelectedArea);
      const isComparing = ref<boolean>(props.comparing > 0);

      watch(
        () => props.value,
        (changed) => {
          tableData.value = changed.options;
          areaName.value = changed.areaName;
        }
      );

      watch(
        () => props.comparing,
        (changed) => {
          isComparing.value = changed > 0;
        }
      );

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const tableHeader = ref([
        {
          name: translate('idColumn'),
          key: 'id',
          sortable: true,
        },
        {
          name: translate('title'),
          key: 'title',
          sortable: true,
        },
        {
          name: translate('value'),
          key: 'value',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
          sortable: false,
        },
      ]);

      onMounted(() => {
        reinitializeComponents();
      });

      const deleteOption = async (id) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate('youCantRevert'),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirmDelete'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          const index = tableData.value.findIndex((v) => v.id === id);
          await store.dispatch(Actions.DELETE_IVE_VALUE, id);
          tableData.value.splice(index, 1);
        }
      };

      const showCreateIveModal = () => {
        emit('show-create-modal', {
          factorId: factorId.value,
          factorTitle: tableTitle.value,
        });
      };
      const showEditIveModal = () => {
        emit('show-edit-modal', {
          value: props.value,
          factorId: factorId.value,
          factorTitle: tableTitle.value,
        });
      };

      const setOptionAsDefault = async (id) => {
        await store.dispatch(Actions.UPDATE_IVE_FACTOR_OPTION_DEFAULT, {
          id: id,
          data: { areaId: selectedArea.value.id },
        });

        Swal.fire({
          text: translate('SUCCESSFULLY_UPDATED_AS_DEFAULT'),
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: translate('ok'),
          customClass: {
            confirmButton: 'btn btn-light-primary',
          },
        });
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        isComparing,
        areaName,
        setOptionAsDefault,
        factorId,
        translate,
        perms,
        tableHeader,
        tableTitle,
        tableData,
        can,
        deleteOption,
        showCreateIveModal,
        showEditIveModal,
      };
    },
  });
